/* eslint-disable @typescript-eslint/no-explicit-any */
import { YMaps, Map as YMap, Clusterer, ZoomControl } from 'react-yandex-maps'
import { useState } from 'react'
import classNames from 'classnames'
import { mapAPI } from 'services'
import { binMapToWorkDays } from 'helpers/binMap'
import Point from './Point'
import moment from 'moment'

export default function Map(): JSX.Element {
  const [center, setCenter] = useState([55.76, 37.64])
  const [selectedMark, setSelectedMark] = useState(-1)
  const {data: pointsList = []} = mapAPI.useGetPointsQuery()

  const handlerPoint = (selectedPointIdx: number) => {
    setCenter(pointsList[selectedPointIdx]?.coordinates?.y && pointsList[selectedPointIdx]?.coordinates?.x ? [pointsList[selectedPointIdx].coordinates.y, pointsList[selectedPointIdx].coordinates.x] : [55.76, 37.64])
    setSelectedMark(pointsList[selectedPointIdx]?.coordinates?.y && pointsList[selectedPointIdx]?.coordinates?.x ? selectedPointIdx : -1)
  }

  return (
    <>
      <div id="map">
        <YMaps
          query={{
            apikey: '43861736-21fa-461e-9c93-6e699b180ed3',
          }}
        >
          <YMap
            defaultState={{
              center: center,
              scroll: false,
              zoom: 11,
              behaviors: ['scrollZoom', 'drag', 'dblClickZoom'],
            }}
            state={{
              center: center,
              scroll: false,
              zoom: 12,
              behaviors: ['scrollZoom', 'drag', 'dblClickZoom'],
            }}
            width="100%"
            height="550px"
          >
            <ZoomControl options={{ float: 'right' }} />
            <Clusterer
              options={{
                preset: 'islands#greenClusterIcons',
                groupByCoordinates: false,
                gridSize: 32,
                clusterize: true,
                clusterDisableClickZoom: true,
              }}
            >
              {pointsList.map((point, index) => (
                <Point point={point} index={index} selectedMark={selectedMark} key={index}/>
              ))}
            </Clusterer>
          </YMap>
        </YMaps>
      </div>

      <div className="big-container map-container">
        <div className="map-pont-list">
          <div className="h2">Приемные пункты</div>
          <div className="point-cont">
            <ul style={{ padding: 0, maxHeight: '100%', overflowY: 'auto' }}>
              {pointsList.map((point, index) => (
                <li className={classNames({
                  active: selectedMark === index
                })} key={point.address} id={String(index)} onClick={() => handlerPoint(index)}>
                  {point.address}
                  <br />
                  {`${binMapToWorkDays(point.workDays)} ${moment.utc(point.startWorkTime, "hh:mm:ss").utcOffset(3).format("HH:mm")} - ${moment.utc(point.endWorkTime, "hh:mm:ss").utcOffset(3).format("HH:mm")}`}
                  <br />
                  {point.cardLink && <a href={point.cardLink} target="_blank" rel="noreferrer">Открыть в Яндекс картах</a>}
                </li>
              ))}
            </ul>
          </div>
          <div className="snoska">* - Цены в приемных пунктах могут отличаться от цен установленных на сайте</div>
        </div>
      </div>
    </>
  )
}
